a{
    // &:not(.mail-preview-body a) {
        color: #555;
    // }
}
body{
    font-family:  'Poppins', 'sans-serif';
}

.upload-list-inline  .ant-upload-list {
    display: flex;
    overflow: auto;
    width: 100%;
    padding-bottom: 10px;
    &::-webkit-scrollbar {
        // width: 0;
        height: 8px;
        // background: transparent;
      }
    .ant-upload-list-picture-container{
        &:not(:last-child){
            margin-right: 6px;
        }
    }
  }

//   .ant-layout-sider{
//     background-color: #690a0d;
//   }

.ant-layout-sider,
.ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark, .ant-menu.ant-menu-dark .ant-menu-sub{
    // background-color: #240102 !important;
    // color: #fff !important;
}
.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
    border: none !important;
}
.admin-layout .ant-layout-sider{
    border-right: 1px solid rgba(0, 0, 0, 0.06);
}