
@mixin btnColor($name,$color) {
    &.btn-#{$name}{
        background-color: $color;
    }
}


@mixin btnColorAtn($name,$color) {
    &.ant-btn-#{$name}:not([disabled]){
        background-color: $color;
        border: 1px solid $color;
        color: #fff;
        svg{
           *{
               fill: #fff ;
           }
    
        }
        &:hover,&:active,&:focus{
            background-color: darken($color,7);
            border: 1px solid darken($color,7);
            color: #fff;
        }
    }
}
@mixin btnColorAtnOutline($name,$color) {
    &.ant-btn-outline-#{$name}{
        background-color: transparent;
        border: 1px solid $color;
        color: $color;
        &:hover,&:active,&:focus{
            background-color: $color;
            border: 1px solid $color;
            color: #fff;
        }
    }
}



.btn{
  @include btnColor('primary',$primary);
  &.btn-circle{
      border-radius: 50%;
  }
}


.ant-btn{
    // line-height: 38px;
    // border-radius: $border-radius;
    &[disabled]{
        svg{
            *{
                fill: #999;
            }
        }
    }
    svg{
        width: 19px !important;
        height: auto !important;

    }
    &.ant-btn-icon-only{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: $border-radius;
    }
    @include btnColorAtn('primary',$primary);
    @include btnColorAtn('success',$success);
    @include btnColorAtn('secondary',$secondary);
    @include btnColorAtn('info',$info);
    @include btnColorAtn('warning',$warning);
    @include btnColorAtn('danger2',$danger);
    @include btnColorAtn('alpha',$alpha);
    @include btnColorAtn('beta',$beta);
    @include btnColorAtn('gamma',$gamma);
    @include btnColorAtn('gray',$gray);
    

    @include btnColorAtnOutline('primary',$primary);
    @include btnColorAtnOutline('success',$success);
    @include btnColorAtnOutline('secondary',$secondary);
    @include btnColorAtnOutline('warning',$warning);
    @include btnColorAtnOutline('info',$info);
    @include btnColorAtnOutline('danger2',$danger);
    @include btnColorAtnOutline('alpha',$alpha);
    @include btnColorAtnOutline('beta',$beta);
    @include btnColorAtnOutline('gamma',$gamma);
    @include btnColorAtnOutline('gray',$gray);


}