.send-widget-wrapper {
    display: flex;
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.579);
    z-index: 1000;
    padding: 20px;
    justify-content: flex-end;
    align-items: flex-end;
    &.send-widget-wrapper-large {
        align-items: center;
        justify-content: center;
        .send-widget {
            width: calc(100% - 30px);
            height: calc(100% - 30px);
        }
    }
}

.send-widget {
    height: 500px;
    width: 600px;
    background-color: #fff;
    z-index: 100;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .sw-header {
        height: 30px;
        background-color: $primary;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px;
        .sw-title {
            margin: 0;
            color: #fff;
            font-weight: 600;
            font-size: 15px;
        }
        .sw-action {
            display: flex;

            a {
                color: #fff;
                &:not(:last-of-type) {
                    margin-right: 15px;
                }
            }
        }
    }
    .sw-body {
        flex: 1;
        position: relative;
        .sw-body-inner {
            position: absolute;
            height: 100%;
            overflow-y: auto;
            top: 0;
            right: 0;
            left: 0;
            .sw-loading {
                position: absolute;
                background-color: #fff9;
                z-index: 10;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .mce-content-body {
                padding: 0 !important;
            }
        }
    }
    .sw-footer {
       
        background-color: #ddd;
        padding: 10px 15px;
        display: flex;
        .sw-btn {
            .ant-btn {
                font-size: 13px;
                
            }
            .ant-btn:first-of-type {
                border-radius: 10px 0px 0px 10px;
            }
            .ant-btn:last-of-type {
                border-radius: 0px 10px 10px 0px;
            }
        }
        .sw-extra-btns {
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            margin-left: 10px;
            list-style: none;
            li {
                a {
                    display: block;
                    font-size: 17px;
                }
                &:not(:last-of-type) {
                    margin-right: 20px;
                }
                .ant-badge-count {
                    font-weight: 400;
                    font-size: 9px;
                    line-height: 15px;
                    min-width: 15px;
                    height: 15px;
                    padding: 0 6px;
                }
            }
        }
    }
}

.sw-form-section {
    padding: 15px;
    height: 100%;
    .ant-form {
        display: flex;
        flex-direction: column;
        height: 100%;
        .ant-form-item {
            margin-bottom: 0;
            &:not(:last-of-type) {
                margin-bottom: 4px;
                border-bottom: 1px solid #ccc;
            }
            &.sw-texteditor {
                flex: 1;
                .ant-form-item-row,
                .ant-form-item-control-input-content,
                .ant-form-item-control-input,
                .ant-form-item-control {
                    height: 100%;
                }
            }
            .ant-input {
                background-color: transparent;
                border: none;
                padding-right: 0;
                padding-left: 0;
                &:focus {
                    box-shadow: none;
                    border: none;
                }
            }
            .ant-select {
                width: 100%;

                padding-right: 0;
                padding-left: 0;
                .ant-select-selector {
                    background-color: transparent;
                    border: none;
                }
                &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
                    .ant-select-selector {
                    box-shadow: none;
                }
            }
            .sw-subject-input {
                .ant-input-group-addon {
                    // background-color: transparent;
                    // border: none;
                    border-left: 1px solid #d9d9d9;
                }
            }
        
        }

    }

    .tox-tinymce {
        border: none !important;
        height: 100% !important;
        flex: 1;
        position: relative;
        .tox-statusbar {
            display: none !important;
        }

        .tox-editor-header {
            margin-bottom: 2px !important;
            border: 1px solid #ddd !important;
            box-shadow: 1px 1px 3px #0004 !important;
            border-radius: 4px;
            overflow: hidden;
        }

        .tox-tbtn--bespoke .tox-tbtn__select-label {
            width: unset !important;
            font-size: 15px;
        }
        .tox-sidebar-wrap {
            
            .tox-edit-area__iframe {
                background: transparent;
                overflow-y: auto;
            }
        }
    }
    .tox {
        .tox-tbtn {
         
            .tox-icon {
                > svg {
                    transform: scale(0.7) !important;
                }
            }
        }
    }
    .ssw-text-area {
        height: 100%;
        position: relative;
        padding-bottom: 40px;
        .sm-menubar {
            box-shadow: 1px 1px 10px -6px #0008;
            display: flex;
            list-style: none;
            margin: 0;
            padding: 0;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 4px;
            padding: 4px;
            overflow: hidden;
            &.disable
            {
                pointer-events: none;
                opacity: 0.5;
            }
            li {
                min-width: 100px;
            }
        }
        .ant-input-textarea {
            height: calc(100% - 20px);
        }
        .ant-input {
            height: 100%;
        }
    }
}

.tox.tox-silver-sink.tox-tinymce-aux
{
    .tox-toolbar__overflow
    {
        max-width: 521px !important;
    }
}

.sw-attachment-list {
    display: flex;
    flex-direction: column;
    padding: 15px 0;
    .sw-attachment-item {
        position: relative;
        display: flex;
        max-width: 400px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        
        
    }
}

.sw-popover {
    width: 500px;
   
}


.sw-to-bar{
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding-right: 35px;
   
   

.sw-to-bar-tag{
    border: 1px solid #ccc;
    display:flex;
    line-height: 1;
    padding: 3px 7px;
    border-radius: 4px;

    span{
        width: calc(100% - 18px);
        padding-right: 5px;
    }
    a{
        display: block;
        width: 18px;
        display: flex;
        align-items: center;
        justify-content: center;

    }
}
    
    .sw-to-bar-label{
        color: #777;
     
    }
    .input-to-area{
        padding-left: 6px;
       flex: 1;
        flex-wrap: wrap;
      
    
        .sw-bar-container{
          
            overflow: hidden auto;
            max-height: 80px;
        }
    }
    .sw-to-bar-action{
        position: absolute;
        right: 0;
        top: 0;
        width: 32.5px;
        height: 30px;
        padding: 0 11px;    
    }
    .sw-bar-placeholder{
        flex: 1 1;
        overflow: hidden;
        color: #bfbfbf;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: flex;
    }
}

.signatures-modal{
    .ant-modal-body{
        .signatures{
            .ant-card{
                cursor: pointer;
                &:hover, &.active{
                    border-color:$primary;
                    .ant-card-head{
                        border-color:$primary;   
                    }
                }
                .ant-card-body{
                    height: 200px;
                    overflow-y: auto;
                }
            }
            
        }
    }
}