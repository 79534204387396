.listcontentlayout {
   height: 100%;
}

.m-data-table-wrapper {
   display: flex;
   height: 100%;
   flex-direction: column;
  
   .m-data-table-header {
      margin-bottom: 8px;
      .lead-filter-blk {
         .ant-btn {
            &.active {
               background-color: $primary;
               color: #fff;
            }
            &:not(.active) {
               border: 1px solid #ccc;
            }
         }
      }
   }
   .m-table-loader-wrapper {
      flex: 1;
      .ant-spin-spinning {
         display: flex;
         align-items: center;
         justify-content: center;
         height: 100%;
         max-height: 100%;
      }
      .ant-spin-container {
         height: 100%;
         .m-table-body {
            height: 100%;
         }
      }
   }
   .m-data-table-body {
      flex: 1;
      // background-color: red;
      position: relative;
      ::-webkit-scrollbar {
         height: 8px;
      }
      .m-table {
         height: 100%;
         &.m-table-ping-left {
            .m-table-cell-fix-left::after {
               box-shadow: inset 10px 0 8px -8px rgb(5 5 5 / 6%) !important;
            }
         }
         &.m-table-ping-right {
            .m-table-cell-fix-right::after {
               box-shadow: inset -10px 0 8px -8px rgb(5 5 5 / 6%) !important;
            }
         }
         table {
            width: 100%;
            table-layout: fixed;
            border-spacing: 0;
         }
         &.m-table-scroll {
            // position: relative;
         }
         .m-table-container {
            display: flex;
            flex-direction: column;
            height: 100%;
            position: relative;
            .m-table-header {
               overflow: hidden;

     .m-table-cell {
      background-color: rgb(244, 244, 244) !important;
      line-height: 1;
      padding: 0 !important;
     }
    }
    .m-table-body {
     flex: 1;
     position: relative;
     overflow: auto scroll;
     background-color: #fff;
     .m-table-row{
      td{

         // transition: all .5s ease;
      }
      &:hover{
         .m-table-cell {
            background-color: #eee;
         }
      }
      .m-table-cell{
    
         .m-table-cell-span{
            display: block;
         overflow: hidden;
         text-overflow: ellipsis;
         .property_address
            {
               color: #1677ff !important;
            }
         }
      }
       &:not(:first-of-type){
         .m-table-cell{
            border-top: 1px solid #f0f0f0;
            // border-bottom: none;
            border-bottom: transparent;
         }
       }
     }
     .m-table-cell-ellipsis{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: keep-all;
      *{
         overflow: hidden;
         white-space: nowrap;
         text-overflow: ellipsis;
         word-break: keep-all;
      }
    }
     .m-table-cell {
      // transition: all .5s ease;
      background-clip: padding-box;
      // border-bottom: 1px solid #eee;
      // border-top: 1px solid #eee;
      &.m-table-cell-fix-right,
      &.m-table-cell-fix-left{
         background-color: #fff !important;
      }
     }
     > table {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
     }
     .m-table-tbody {
      .m-table-row {
       &.m-table-row-is-selected {
        .m-table-cell {
         background-color: lighten($primary, 55);
        }
       }
      }
     }
    }
    .m-table-cell {
     padding: 9px 8px;
     text-align: left;
     position: relative;
     font-size: 14px;
     &.m-table-cell-has-filter {
      .mt-header-cell-span {
       display: flex;
       align-items: center;
       justify-content: space-between;
       line-height: 1;
       .mt-filter-btn {
         height: auto;
         line-height: 1;
         padding: 0;
         padding-right: 3px;
        i {
         font-size: 13px;
        }
       }
      }
     }

               .m-table-resize-col {
                  position: absolute;
                  right: 0;
                  top: 0;
                  height: 100%;
                  width: 2px;
                  background: #ddd;
                  cursor: col-resize;
                  user-select: none;
                  touch-action: none;
                  height: 80%;
                  &.is-resizing {
                     background-color: #666;
                  }
               }
               &.m-table-cell-last {
                  .m-table-resize-col {
                     display: none;
                  }
               }

     &.m-table-selection-column {
      text-align: center;
     }
     &.m-table-cell-fix-left {
      z-index: 10 !important;
      position: relative;
      
      &::after {
       position: absolute;
       top: 0;
       right: 0;
       bottom: -1px;
       width: 30px;
       transform: translateX(100%);
       transition: box-shadow 0.3s;
       content: '';
       pointer-events: none;
      }
     }
     &.m-table-cell-fix-right {
        z-index: 10;
        position: relative;
      //   background-color: #fff;
        .m-table-resize-col {
         display: none;
        }
        &::after {
         position: absolute;
         top: 0;
         left: 0;
         bottom: -1px;
         width: 30px;
         transform: translateX(-100%);
         transition: box-shadow 0.3s;
         content: '';
         pointer-events: none;
        }
       }

    }
   }
  }
 }

 .m-table-custom-empty-block { 
   display: flex;
   width: 100%;
   max-width: 70vw;
   margin: auto;
   height: 100%;
   align-items: center;
   justify-content: center;
   font-size: 17px;
}

 .m-table-placeholder {
  border-radius: 10px;
  background-color: #fff9;
  height: 100%;
  display: flex;
  align-items: center;
  width: 100vw;
  justify-content: center;
  padding: 50px;
  position: sticky;
  left: 0;
 }
}

.empty-block-style {
   display: flex;
   font-size: 17px;
   height: 100%;
   align-items: center;
   justify-content: center;
   width: 100%;
   margin: auto;
}

.dashboard-card.ant-card {
   margin-top: 10px;
   height: 100% !important;
}

.birthday-wishes-card.ant-card {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
   .ant-card-body {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70vw;
      font-size: 17px;
      height: 100%;
   }
}

// .dashboard-no-data {
//    display: flex;
//    height: 100%;
//    align-items: center;
//    justify-content: center;
//    width: 100%;
//    max-width: 70vw;
//    margin: auto;
//    font-size: 17px;
// }

.m-table-row-action {
   display: flex;
   background-color: lighten($primary, 40);
   align-items: center;
   .m-table-row-action-close {
      width: 40px;
      text-align: center;
   }
   .m-table-row-action-body {
      flex: 1;
      display: flex;
      align-items: center;
      .ant-btn {
         display: flex;
         align-items: center;
         svg,
         i {
            margin-right: 3px;
         }
         svg {
            width: 14px !important;
         }
         &:not(.m-action-toggle) {
            color: #fff;
            svg {
               * {
                  fill: #fff;
               }
            }
         }
      }
   }
}

.mt-filter-block {
   overflow: hidden;
   display: flex;
   flex-direction: column;

   .mt-filter-block-header {
      .mt-filter-block-search {
         background-color: #e4e4e4;
         padding: 8px;
      }
      .mt-filter-block-selectAll {
         padding: 8px 10px;
         border-bottom: 1px solid #d6d6d6;
      }
   }
   .mt-filter-block-body {
      flex: 1;
      max-height: 300px;
      // min-height: 150px;
      overflow: auto;
      .ant-menu {
         .ant-menu-item {
            margin: 0 !important;
            padding: 8px 10px;
            width: 100%;
            border-radius: 0;
            height: auto;
            line-height: 1;

            max-width: 500px;

         }
      }
   }
   .ant-empty{
      padding: 10px 0;
      .ant-empty-image{
         height: 50px;
      }
   }
   .mt-filter-block-footer {
      background-color: #f0f0f0;
      padding: 8px;
   }
}


.integration-btn {
   button {
      border: none;
      background-color: transparent;
      cursor: pointer;
   }
}