.ui-list-card {
    background: #fff;
    border-radius: 0.25rem;
    border: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    .content {
    }
}

.filter-overlay {
    width: 300px;
    border: 1px solid #ccc;
    background-color: #fff;
    box-shadow: 1px 1px #0003;
    padding: 15px;
}

.shedl-email {
    .card {
        padding: 10px 15px;
        cursor: pointer;
        text-align: center;
        i {
            font-size: 20px;
        }
        h5 {
            margin-bottom: 3px;
        }
        p {
            color: #aaa;
            margin: 0;
        }
    }
}

.ant-table-filter-dropdown {
    .ant-dropdown-menu-item {
        padding: 6px 12px;
        .ant-dropdown-menu-title-content {
            align-items: center;
            display: flex;
            line-height: 1;
            .ant-checkbox-wrapper {
                margin-right: 0;
                label {
                }
            }
            > span {
                font-size: 15px;
            }
        }
    }
    .ant-table-filter-dropdown-btns {
        padding: 6px 12px;
        .ant-btn-sm {
            height: 1.7rem !important;
            // padding: 0.28rem 0.8rem;
            // font-size: 0.7875rem;
            // font-weight: 600;
            // border-radius: 0.25rem;
        }
    }
}

.content-page {
    padding-bottom: 0;
    .crm-footer {
        padding: 15px 0;
        padding-bottom: 20px;

        p {
            margin: 0;
        }
    }
    .content {
        // margin-bottom: 60px;
    }
}

.avatar-click {
    cursor: pointer;
    &:hover {
        p {
            color: $primary;
        }
    }
    p {
        margin: 0;
    }
}

.pr-left-itm {
    padding: 10px 0;
    &:not(:last-of-type) {
        border-bottom: 1px solid rgb(230, 230, 230);
    }
    .pr-left-re {
    }
    .ul-list {
        list-style: none;
        margin: 0;
        padding: 0;
        .ant-btn-sm {
            height: 1.5rem;
            line-height: 1;
        }
        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            &:not(:last-of-type) {
                margin-bottom: 7px;
            }
            &:hover {
                span {
                    opacity: 1;
                }
            }
            span {
                width: 67px;
                opacity: 0;
                transition: all 0.5s ease;
            }
            // span{
            //     display: flex;
            //     align-items: center;
            // }
            p {
                margin: 0;
                font-size: 13px;
            }
            a {
                display: inline-block;
                padding: 0px 5px;
                font-size: 13px;
            }
        }
    }
}

.email-small {
    .sun-editor .se-btn {
        width: 25px;
        height: 25px;
        > svg {
            width: 13px;
            height: 13px;
        }
    }
}

.action-card {
    box-shadow: 1px 1px 10px #ccc;
    &.action-add {
        text-align: center;
        cursor: pointer;
        transition: all 0.5s ease;
        &:hover {
            background-color: #eee;
        }
    }
}

.page-section-inner {
    margin-top: 15px;
    &.no-bg {
        background: transparent;
        padding: 0;
        > .ant-card-head,
        > .ant-card-body {
            padding: 0;
        }
        > .ant-card-head {
            background: transparent;
            .ant-card-extra,
            .ant-card-head-title {
                padding: 0;
            }
        }
    }

    > .ant-card-head {
        margin-bottom: 20px;
        .ant-card-head-title {
            font-size: 20px;
        }
    }
    .ant-card-extra {
        a {
            font-size: 20px;
        }
    }
}

.action-timeline-view {
    margin-left: 20px;
    .tim-dot {
        text-align: center;
        h5 {
            color: #666;
        }
        i {
            font-size: 19px;
        }
    }
    .ant-timeline-item-content {
        margin: 0 0 0 45px;
    }
}

.collapsing {
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

//   listing-details-view

.listing-details-view {
    .ant-modal {
    }
}
.listing-details-modal {
    .ant-modal-close {
        right: -15px;
        top: -15px;
        .ant-modal-close-x {
            background-color: $primary;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            box-shadow: 1px 1px 15px #0004;
            line-height: 30px;
            span {
                color: #fff;
            }
        }
    }
    .img--slider {
        width: 100%;
        padding-bottom: 50%;
        background-color: $primary;
        * {
            position: absolute;
        }
        img {
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .li-con-item {
        h5 {
            font-size: 20px;
        }
        .d-listc {
            display: flex;
            list-style: none;
            margin: 0;
            padding: 0;
            justify-content: flex-end;
            li {
                text-align: center;
                padding: 10px;
                &:not(:last-of-type) {
                    border-right: 1px solid #ddd;
                }
                i {
                    font-size: 20px;
                    margin-bottom: 6px;
                    color: #bbb;
                }
                h6 {
                    font-size: 17px;
                    margin: 0;
                    font-weight: 500;
                }
            }
        }
    }
    .ant-collapse-item.ant-collapse-item-active {
        .ant-collapse-header {
            background-color: #ccc;
        }
    }
}

.pri--selector {
    // width: 100%;
    .pr-select {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        padding: 15px;
        cursor: pointer;
        color: #888;
        &.active {
            background-color: $primary;
            color: #fff;
        }
        &:hover {
            background-color: $primary;
            color: #fff;
        }
    }
}

.color--selector {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin: -7px;
    li {
        margin: 7px;
        &.active {
            border: 3px solid #000;
            box-shadow: 1px 1px 6px #0005;
        }
        cursor: pointer;
        display: flex;
        padding: 4px 10px;
        // line-height: 1;
        border: 3px solid transparent;
        border-radius: $border-radius;
        color: #fff;
        &.color--red {
            background-color: $red;
        }
        &.color--cyan {
            background-color: $cyan;
        }
        &.color--yellow {
            background-color: $yellow;
        }
        &.color--green {
            background-color: $green;
        }
        &.color--blue {
            background-color: $blue;
        }
        &.color--black {
            background-color: $black;
            &.active {
                border-color: $primary;
            }
        }
    }
}

.overview-card {
    margin: 0;
    h4 {
        font-size: 18px;
    }
}

.detls-table {
    margin: 0;
    td {
        padding: 10px 0px;
        * {
            padding: 0;
        }
        &:first-of-type {
            width: 40%;
            position: relative;
            &::after {
                content: ":";
                position: absolute;
                right: 30px;
            }
        }
        &:last-of-type {
            width: 60%;
        }
    }
}

// inbox

.inbox-section {
    .ant-card-head {
        padding: 0px 15px;
        margin-bottom: 15px;
    }
    .cont-list {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            width: 100%;
            padding: 13px 15px;
            transition: all 0.5s ease;
            &:hover,
            &.active {
                background-color: rgb(236, 245, 252);
                box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.185);
            }
            &:not(:last-of-type) {
                border-bottom: 1px solid #eee;
            }
        }
    }
}

.email-inbox {
    .em-in-item {
        .ant-card-body {
            display: block;
        }
        &.closed {
            .ant-card-body {
                display: none;
            }
        }
        > .ant-card-head {
            .ant-card-head-wrapper {
                flex-wrap: wrap;
                .ant-card-head-title {
                    width: calc(100% - 70px);
                    padding-right: 20px;
                }
                .ant-card-extra {
                    width: 70px;
                }
            }
        }
    }
}

.em-time-stamp {
    text-align: center;
    padding: 7px 0;
}

.notify-list {
    .ant-list-item {
        background-color: #fff;
        padding: 15px 15px;
        // border: 1px solid #eee;
        .ant-list-item-meta-title {
            margin: 0;
            .ant-badge-count {
                margin: 0;
                font-size: 10px;
                padding: 4px 8px;
                line-height: 1;
                height: unset;
            }
        }
        &:not(:last-of-type) {
            margin-bottom: 5px;
            // border-bottom: 1px solid #ddd;
        }
    }
}

.infi-data-table {
    .react-resizable {
        position: relative;
        background-clip: padding-box;
    }

    .react-resizable-handle {
        position: absolute;
        right: -5px;
        bottom: 0;
        z-index: 1;
        width: 10px;
        height: 100%;
        cursor: col-resize;
    }
}


.item-block-li{
    display: flex;
    border: 1px solid rgb(240, 240, 240);
    border-radius: 7px;
    padding: 5px 15px;
    align-items: center;
    .action{
        opacity: 0;
    }
    &:hover{
        .action{
            opacity: 1;
        }
    }
    .ant-btn{
        border-radius: 50%;
        line-height: 1;
        width: 25px;
        height: 25px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        // border: 1px solid #555;
        i{
            font-size: 11px;
        }
    }
    span{
        // flex: 1;
        display: flex;
        &.content{
            padding-right: 4px;
            width: calc(100% - 120px);
        }
        &.action{
            // display: flex;
            flex-grow: 1;
            align-items: center;
            justify-content: flex-end;
            // width: 120px;
            column-gap: 4px;
            align-self: center;
            // text-align: right;
        }
    }
    .blk-tag{
        color: #333333;
    margin-left: 5px;
    border: 1px solid #ccc;
    padding: 4px;
    border-radius: 4px;
    font-size: 12px;
    height: max-content;
    }

}
.action-plan-blk{

}


.activity--blk{
    height: 100%;
    display: flex;
    column-gap: 10px;
    .activity-left{
        width: 380px;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
      
    }
    .activity-right{
        flex: 1;                                
        box-shadow: 0px 0px 3px #0004;
        position: relative;
        .inner-area-act{
            position: absolute;                 
            left: 0;
            width: 100%;
            height: 100%;
            overflow: auto;
            top: 0;
        }
    }
}

.activity-layout-crd{
    display: flex;
    flex-direction: column;
    height: 100%;
    .card-body-inner{
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
   
    .activity-collaps{
        .ant-collapse-header{
            h5{
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 6px;
            }
            h6{
                color: #888;
            }
            .ant-tag{
                margin-top: 4px;
                font-size: 12px;
            }
            .text-muted{
                font-size: 12px;
                font-weight: 500;
            }
        }
        .collaps-flwid{
            .ant-collapse-header-text{
                width: 100% !important;
                .flwid-header{
                    margin-right: 70px;
                }
                .reply-i{
                    position: absolute;
                    top: 8px;
                    right: 25px;
                }
                .forward-i{
                    position: absolute;
                    top: 8px;
                    right: -10px;
                }
                
            }
        }
    }
}   
}


.task-list{
    .ant-list-items{
        .ant-list-item{
            .ant-list-item-meta-avatar{
                svg{
                    width: 18px;
                    height: auto;
                }
            }
        }
    }
}

.lead-dtl-nav{
    display: flex;
    margin: 0;
    list-style: none;
    padding: 7px 0;
    border-bottom: #999;
    li{
        a{
            display: block;
            padding: 5px 16px;
            border: 1px solid #bbb;
            border-radius: 5px;
            &.active{
                background-color: $primary;
                color: #fff;
            }
        }
        &:not(:last-of-type){
            margin-right: 10px;
        }
    }
}

.lead-block-btn {
    background-color: gray;
    color: white;
    width: fit-content !important;
    border-radius: 10px !important;
    border-color: transparent !important;

    &:hover {
        background-color: gray;
        color: white;
    }
}

.lead-unblock-btn {
    background-color: #ED1C24;
    color: white;
    width: fit-content !important;
    border-radius: 10px !important;
    border-color: transparent !important;

    &:hover {
        background-color: #ED1C24;
        color: white;
    }
}