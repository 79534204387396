.b-day-wish-form {
 .menu {
  .ant-radio-button-wrapper {
   min-width: 80px;
   margin-bottom: 14px;
   text-align: center;
   &.ant-radio-button-wrapper-checked {
    background-color: $primary;
    border-color: $primary;
    color: white;
   }
  }
 }
}

// .signature-section {
//  .tox-tinymce {
//   &[role='application'] {
//    height: calc(100vh - 300px) !important;
//    min-height: 280px !important;
//   }
//  }
// }

.tiny-loading-sec{
    height: calc(100vh - 300px) !important;
    min-height: 280px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signatures{
    .signature-item{
        border: 1px solid #ccc;
        font-size: 14.5px;
        color: black;
        padding: 12px 10px;
        border-radius: 6px;
        background-color: #f4f4f4;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        &.active,&:hover {
            border-color: $primary;
            color:$primary;
        }
       
    }
}

.signature-section{
    .ant-form{
        .make-as-default-form-item{
            .ant-form-item-label{
                display: flex;
                padding: 0;
            }
        }
    }
}