.u-onboard {
 height: 100vh;
 background: url('../../../public/images/user-onboard-bg.png');
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
 overflow: auto;
 .u-onboard-close {
  position: absolute;
  right: 20px;
  top: 20px;
  .close {
   border: 1px solid white;
   color: white;
   padding: 2px 12px;
   font-size: 20px;
   border-radius: 4px;
   cursor: pointer;
   i {
    font-weight: 500;
   }
  }
 }
 .content {
  width: 100%;
  height: 100%;
  padding: 20px 70px;
 
  .content-row {
   height: 100%;
   align-items: center;
   justify-content: center;
   //    display: flex;
   //    gap: 35px;
  }
  @media (max-width: 1199px) {
    padding: 20px;
    .content-row{
      flex-flow: column;
    }
   }
  // .left-wrapper {
  //  max-width: min-content;
  // }
  .left {
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   @media (max-width: 1200px) {
    width: 100%;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
   }
   .left-content {
    z-index: 1;
    .left-title {
     color: white;
    //  font-size: 36px;
     font-weight: 300;
     margin-bottom: 0;
     line-height: 46px;
     font-size: clamp(24px, 3.7vh, 36px);
    }
    .left-name {
     color: $primary;
    //  font-size: 38px;
     font-weight: 600;
     margin-bottom: 0;
    //  line-height: 46px;
    font-size: clamp(26px, 3.8vh, 38px);
    line-height: clamp(20px, 5vh, 46px);
    }
    .left-desc {
     color: white;
     font-size: 18px;
     margin-top: 10px;
     margin-top: clamp(0px, 1vh, 10px);
    font-size: clamp(14px, 1.8vh, 18px);
    }
   }
   .left-video {
    display: flex;
    padding: 23px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 38px;
    border: 1px solid #fff;
    background: rgba(0, 0, 0, 0.71);
    backdrop-filter: blur(6.5px);
    width: fit-content;
    .video-wrap {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: flex-start;
    //  gap: 50px;
    gap: clamp(20px, 5vh, 30px);
    }
    .video-title {
     color: #fff;
    //  font-size: 24px;
     font-weight: 500;
     font-size: clamp(18px, 2.4vh, 24px);
    }
    .video-subtitle {
     color: #fff;
    //  font-size: 22px;
     font-weight: 500;
     margin-bottom: 0;
     font-size: clamp(16px, 2.4vh, 24px);
    }
    .video-desc {
     color: #fff;
    //  font-size: 18px;
     margin-bottom: 0;
     font-size: clamp(13px, 1.8vh, 18px);
    }
    .video-img {
     max-width: 100%;
     cursor: pointer;
    }
   }
  }
  .left-wrapper {
   padding-left: 16px;
   padding-right: 16px;
  }
  .right-wrapper {
   margin-top: auto;
   margin-bottom: auto;
   padding-left: 16px;
   padding-right: 16px;
   .right {
    display: flex;
    align-items: center;
    @media (max-width: 1200px) {
     flex-direction: column;
    }
    .setup {
     padding: 45px 65px;
     border: 1px solid white;
     border-radius: 48px;
     border: 1px solid white;
     background: rgba(0, 0, 0, 0.71);
     backdrop-filter: blur(6.5px);
     @media (max-width: 1200px) {
      padding: 20px;
     }
     .head {
      color: $primary;
      font-size: 36px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 0;
     }
     .desc {
      color: white;

      text-align: center;
      font-size: 18px;
     }
    }
    .details {
     height: max-content;
     //  position: absolute;
     //  top: 50%;
     //  bottom: 50%;
     //  transform: translate(90%, -50%);
     //  //  right: 40px;
     //  right: 0px;

     //  max-width: 400px;
     width: clamp(300px, 25vw, 400px);
     padding: 65px 25px;
     border-radius: 38px;
     border: 1px solid #000;
     background: #fff;
     display: flex;
     flex-direction: column;
     align-items: center;
     gap: 18px;
     flex-shrink: 0;
     margin-left: -50px;
     z-index: 10;
    //  transition-property: opacity;
    //         transition-duration: 200ms;
    //         transition-timing-function: ease-in-out;
    //  .ant-tooltip-disabled-compatible-wrapper{
    //     button{
    //         width: 100%;
    //     }
    //  }
     @media (max-width: 1200px) {
      position: unset;
      transform: unset;
      margin: 20px 0;
     }

     .icon {
      //   padding: 23px 34px;
      color: $primary;
      //   font-size: 64px;
      border-radius: 14px;
      border: 1px solid $primary;
      font-size: clamp(35px, 6.5vh, 64px);
      padding: clamp(15px, 2.5vh, 23px) clamp(25px, 3.5vh, 34px);
     }
     .head {
      color: #000;
      text-align: center;
      //   font-size: 24px;
      font-size: clamp(20px, 2.4vh, 24px);
      font-weight: 500;
     }
     .desc {
      color: #000;
      text-align: center;
      //   font-size: 18px;
      font-size: clamp(15px, 1.8vh, 18px);
     }
     .btn {
      font-size: 22px;
      background-color: $primary;
     }
    }
   }
  }
 }
}

.user-onboard-setup-items {
 display: flex;
 flex-direction: column;
 gap: 22px;
 flex-shrink: 0;
 .item {
  border-radius: 14px;
  background-color: white;
  color: black;
  display: flex;
  padding: 18px 22px;
  align-items: center;
  gap: 18px;
  align-self: stretch;
  cursor: pointer;
  &:hover {
    i {
     color: $primary;
    }
   }
  i {
   transition-delay: 150ms;
   transition-property: color;
  }
//   &:not(.completed-item) {
// //    cursor: pointer;
//    &:hover {
//     i {
//      color: $primary;
//      // transition-delay:150ms;
//      // transition-property: color;
//     }
//    }
//   }
  &.active {
   i {
    color: $primary;
   }
  }
  .fa-check-circle {
   color: green !important;
   font-size: 18px;
   margin-left: auto;
  }
  // .completed-item{
  //   cursor: auto !important;
  //   color: black;
  //   &:hover{
  //     color: black;
  //   }
  // }
 }
}

.app-download-modal {
 width: unset !important;
 max-width: 450px;
 padding: 0;
 position: absolute;
 z-index: 1000;
 bottom: 20px;
 right: 20px;

 .content {
  border-radius: 14px;
  padding: 24px;
  min-width: 320px;
  // background-color: black;
  background: url('../../../public/images/user-onboard-bg.png');
  background-position: center;
  background-size: cover;
  // .ant-modal-close {
  //  display: none;
  // }
  .close {
   position: absolute;
   top: 6px;
   right: 10px;
   font-size: 26px;
   i {
    color: white;
    cursor: pointer;
   }
  }
  .body {
   display: flex;
   flex-direction: column;
   gap: 14px;
   max-width: 210px;
   width: 100%;
   margin-left: auto;
   margin-right: auto;
   img {
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
    // width: 100;
   }
   p {
    font-size: 22px;
    text-align: center;
    color: white;
    font-weight: 600;
    margin-top: 14px;
    margin-bottom: 0;
   }
  }
  .scan-icon-wrapper {
   position: absolute;
   top: -24px;
   left: 50%;
   transform: translateX(-50%);
   background-color: white;
   border-radius: 50%;
   padding: 10px 10px;
   display: flex;
   align-items: center;
   // svg{
   //   font-size: 28px;
   // }
  }
  .or-wrapper {
   display: flex;
   align-items: center;
   max-width: 280px;
   margin-left: auto;
   margin-right: auto;
   color: white;
   .or-wrapper-lines {
    width: 50%;
    height: 1px;
    background-color: white;
   }
   .or-text {
    padding: 0 10px;
   }
  }
  .footer {
   display: flex;
   justify-content: center;
  }
 }
}

.on-board-vid-modal {
 .ant-modal-content {
  background-color: unset;
  box-shadow: none;
 }
 .ant-modal-close {
  display: none;
 }
 .ant-modal-body {
  padding: 0;
 }
}

.user-onboard-modal {
 width: unset;
 max-width: 550px;
 padding: 0;
 position: absolute;
 z-index: 1000;
 right: 10px;
 padding: 10px;
 //  height: 100%;
 bottom: 0;
 //  .ant-modal-body {
 //   padding: 34px;
 //  }
 //  .ant-modal-content {
 //   border-radius: 14px;
 //   background-color: black;
 //   .ant-modal-close {
 //    display: none;
 //   }
 .content {
  border-radius: 14px;
  background-color: black;
  // padding: 24px;
  padding: clamp(16px, 2.5vh, 24px);

  .user-onboard-setup-items {
   gap: 16px;
   .item {
    // padding: 12px 14px;
    // font-size: 12px;
    font-size: clamp(11px, 1.3vh, 14px);
    gap: 14px;
    padding: clamp(9px, 1.7vh, 15px) clamp(11px, 1.6vh, 18px);
    border-radius: clamp(8px, 1.5vh, 14px);
   }
  }
 }
 .header {
  .actions {
   cursor: pointer;
   color: white;
   width: max-content;
   margin-left: auto;
   font-size: 19px;
   display: flex;
   justify-content: center;
   align-items: center;
//    .minimize {
//     border: 1px solid white;
//     height: 1px;
//     display: inline-block;
//     width: 18px;
//     margin-right: 8px;
//     cursor: pointer;
//    }
i{
    padding-left: 5px;
    padding-right: 5px;
}
  }
  .header-content {
   display: flex;
   gap: 14px;
   .icon {
    color: white;
    font-size: 28px;
    margin-top: 8px;
   }
   .title {
    color: white;
    //  font-size: 24px;
    font-weight: 600;
    margin-bottom: 0;
    font-size: clamp(19px, 2.5vh, 24px);
   }
   .subtitle {
    color: white;
    //  font-size: 16px;
    // margin-bottom: 2px;
    font-size: clamp(14px, 2vh, 16px);
   }
  //  .go-to-landing {
  //   color: $primary;
  //   //  font-size: 15px;
  //   cursor: pointer;
  //   font-size: clamp(13px, 2vh, 15px);
  //   &:hover {
  //    text-decoration: underline;
  //   }
  //  }
  }
 }

 //  }
}

.user-onboard-minmized {
//  position: absolute;
//  bottom: 28px;
 position: absolute;
//  bottom: 28px;
 background-color: black;
//  padding: 10px;
 border-radius: 4px;
 color: white;
 // font-weight: 600;
 display: flex;
 justify-content: space-between;
 z-index: 100;
 right: 28px;
 width: 100%;
 max-width: 250px;
 font-size: 15px;
//  padding: 4px 8px;
 bottom: 0;

 .setup-title{
  // padding: 10px;
 padding: 4px 8px;

  width: calc(100% - 60px);
  cursor: pointer;
 }
 .icons {
  // padding: 10px;
  display: flex;
  align-items: center;
  font-weight: 500;
  i {
   cursor: pointer;
  //  padding: 12px;
  padding: 4px 8px;

  }
 }
}


@media (min-width: 1199px){
  .content{
    .left-wrapper{
      max-width: 25%;
    }
  }
}

@media (min-width: 1199px) and (max-width:1400px) {

  .u-onboard{
    .content{
      // .left-wrapper{
      //   max-width: 25%;
      // }
      .right-wrapper{
        .right{
          .details{
            height: 400px;
            justify-content: space-between;
            padding: 40px 20px;
            margin-left:-25px;
            
          }
          .setup{
            border-radius: 22px;
            padding: 20px 35px;
            .head{
              font-size: 28px;
            }
            .desc{
              font-size: 15px;
            }
          }
        }
        .user-onboard-setup-items{
          gap: 16px;
          &.is-page{
            .item{
              padding: 14px 18px;
              font-size: 12px;
              gap: 14px;
            }
          }
        }
      }
    }
    
  }
  
  
}