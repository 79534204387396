.dashboard-page {
 background-color: rgb(241, 241, 241);
 height: 100%;
 overflow: auto;

 @mixin dashCard($color) {
  z-index: unset;
  border-radius: 13px;
  background: $color;
  box-shadow: none;
 }

 .ant-row {
  .ant-col {
   &:nth-child(1) {
    .dash-card {
     @include simpleBg($primary);
     z-index: unset;
     background: linear-gradient(180deg, #f0565c 0%, #d91119 100%);
    }
   }
   &:nth-child(2) {
    .dash-card {
     @include simpleBg($purple);
     z-index: unset;
     background: linear-gradient(180deg, #8d83e6 0%, #4f3fd8 100%);
    }
   }
   &:nth-child(3) {
    .dash-card {
     @include simpleBg($cyan);
     z-index: unset;
     background: linear-gradient(180deg, #45dba1 0%, #03b36f 100%);
    }
   }
   &:nth-child(4) {
    .dash-card {
     @include simpleBg($orange);
     z-index: unset;
     background: linear-gradient(180deg, #fc9e50 0%, #f06e02 100%);
    }
   }
   &:nth-child(5) {
    .dash-card {
     @include simpleBg($success);
    }
   }
   &:nth-child(6) {
    .dash-card {
     @include simpleBg($indigo);
    }
   }
   &:nth-child(7) {
    .dash-card {
     @include simpleBg($indigo);
    }
   }
  }
 }
 .ant-card {
  box-shadow: 1px 1px 10px -2px #0004;
  border: none;
 }
 .dash-card {
  position: relative;
  color: #fff;

  // &.dash-card-primary{
  //     background-color: darken($primary, 18);
  // }
  // &.dash-card-secondary{
  //     background-color: darken($primary, 9);
  // }
  .dash-title {
   font-weight: 600;
//    font-size: 19px;
    font-size: Clamp(19px, 1.2vw, 23px);
    display: flex;
    align-items: center;
   color: #fff;
   svg{
    width: Clamp(19px, 1.2vw, 23px);
    height: Clamp(19px, 1.2vw, 23px);
    margin-right: 8px;
   }
  }
  .extra-dash {
    width: 100%;
   display: flex;
   justify-content: space-between;
   margin: 0;
   padding: 0;
   list-style: none;
   li {
    padding: 6px 7px;
    // box-shadow: 1px 1px 3px -1px #0004;
    // border-radius: 5px;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    // width: 50%;
    width: calc(50% - 5px);
    border: 1px solid;
    // margin:0 5px;
    border-radius: 6px;
    cursor: pointer;
    &:hover {
     p {
      text-decoration: underline;
     }
    }
    h6 {
    //  font-size: 13px;
    //  margin-bottom: 0;
     color: #fff;
     line-height: 1;
     text-transform: capitalize;
     margin-bottom: 8px;
     font-size: clamp(10px, 0.8vw, 13px);
    }
    p {
    //  font-size: 15px;
    //  font-size: 800;
     color: #fff;
     margin: 0;
     line-height: 1;
     font-size: clamp(10px, 0.8vw, 13px);
    }
    &:not(:last-of-type) {
     //  border-right: 2px solid #0002;
     // margin-right: 10px;
    }
   }
  }
  .dash-icon {
   position: absolute;
   right: 10px;
   top: 10px;
   overflow: hidden;
   height: 70px;
   width: 70px;
   background-color: #fff2;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 10px;
   box-shadow: 1px 1px 10px -2px #0006;
   i {
    font-size: 20px;
   }
  }
 }

 .ant-card-body {
  overflow: auto;
  height: calc(100% - 60px);
  padding: 16px;
  &::-webkit-scrollbar {
   width: 8px;
   height: 8px;
  }
 }
 .dashboard-banners {
  // .ant-carousel {
  //  height: 480px;
  // }
  .dashboard-carousel {
  //  height: 100%;
   .slick-next {
    right: 21px;
    color: white;
    font-size: 35px;
    z-index: 1;
   }
   .slick-prev {
    left: 8px;
    color: white;
    font-size: 35px;
    z-index: 1;
   }
  //  .slick-list {
  //   height: 100%;
  //   .slick-track {
  //    height: 100%;
  //    .slick-slide {
  //     height: 100%;
  //     display: flex;
  //     align-items: center;
  //     margin-left: auto;
  //     margin-right: auto;
  //     div {
  //      height: 100%;
  //      display: flex;
  //      align-items: center;
  //      width: 100%;
  //      .banner-img {
  //       object-fit: contain;
  //       max-height: 460px;
  //       width: 100%;
  //       background-color: #d4d4d4;
  //       height: 100%;
  //       cursor: pointer;
  //      }
  //     }
  //    }
  //   }
  //  }
  }
  // &.sm-dashboard-banners {
  //  .ant-carousel {
  //   height: 240px;
  //  }
  // }
 }
}

.dashboardcard-icons {
 svg {
  height: 19px;
  width: 19px;
  path {
   fill: white;
  }
 }
}

.dashboard-table-items {
 display: -webkit-box;
 -webkit-line-clamp: 1;
 -webkit-box-orient: vertical;
 overflow: hidden;
 max-width: 180px;
 width: 180px;
}
